import React from 'react';

import { ItipsLogoLink } from '../common/Logo';
import HamburgerMenu from './HamburgerMenu';
import * as HeaderStyle from './Header.module.scss';

export const NavList = ({ name, link }: { name: string; link: string }) => {
  return (
    <li>
      <a href={link}>{name}</a>
    </li>
  );
};

const LangChangeButton = () => {
  return (
    <div className={HeaderStyle.langChangeButton}>
      <a href="https://itips.blue/">
        <button>JP</button>
      </a>
    </div>
  );
};

const CareerButton = () => {
  return (
    <div className={HeaderStyle.careerButton}>
      <a href="#contact">
        <button>Carrer</button>
      </a>
    </div>
  );
};

const Header = () => {
  return (
    <header id="top" className={HeaderStyle.wrapper}>
      <div className={HeaderStyle.logo}>
        <ItipsLogoLink />
      </div>
      <nav className={HeaderStyle.navigation}>
        <ul>
          <NavList name={'Mission'} link="#mission" />
          <NavList name={'About'} link="#about" />
          <NavList name={'Company'} link="#company" />
          <NavList name={'Contact'} link="#contact" />
        </ul>
      </nav>
      <div className={HeaderStyle.right}>
        <LangChangeButton />
        <CareerButton />
      </div>
      <div className={HeaderStyle.hamburger}>
        <HamburgerMenu />
      </div>
    </header>
  );
};

export default Header;
