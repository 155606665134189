import * as Collapsible from '@radix-ui/react-collapsible';
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';

import { MyImage } from '@/components/common/MyImage';

import * as ProfileStyles from './Profile.module.scss';

const ProfileText = () => {
  return (
    <div className={ProfileStyles.textContainer}>
      <div>
        <p className={ProfileStyles.label}>Brief Biography</p>
        <p className={ProfileStyles.text}>
          <span className={ProfileStyles.month}>Mar.</span>
          <span className={ProfileStyles.year}>2008</span>
          <span>B.A. in Policy Management, Keio University</span>
        </p>
        <p className={ProfileStyles.text}>
          <span className={ProfileStyles.month}>Mar.</span>
          <span className={ProfileStyles.year}>2010</span>
          <span></span>Master of Media and Governance, Keio University
        </p>
        <p className={ProfileStyles.text}>
          <span className={ProfileStyles.month}>Nov.</span>
          <span className={ProfileStyles.year}>2007</span>
          <span>Founder & Representative Director of efoop Inc.(Japan)</span>
        </p>
        <p className={ProfileStyles.text}>
          <span className={ProfileStyles.month}>Apr.</span>
          <span className={ProfileStyles.year}>2010</span>
          <span>Grain Unit of Mitsubishi Corp.Commodity/Freight Trader, Investment Manager</span>
        </p>
        <p className={ProfileStyles.text}>
          <span className={ProfileStyles.month}>Aug.</span>
          <span className={ProfileStyles.year}>2017</span>
          <span>Independent Turnaround Consultant</span>
        </p>
        <p className={ProfileStyles.text}>
          <span className={ProfileStyles.month}>Apr.</span>
          <span className={ProfileStyles.year}>2019</span>
          <span>Representative Director of Japan Metal Group.(Japan/India)</span>
        </p>
        <p className={ProfileStyles.text}>
          <span className={ProfileStyles.month}>Jan.</span>
          <span className={ProfileStyles.year}>2022</span>
          <span>Founder & Representative Director of iTips Inc.(Japan)</span>
        </p>
        <p className={ProfileStyles.text}>
          <span className={ProfileStyles.month}>Jun.</span>
          <span className={ProfileStyles.year}>2022</span>
          <span>Founder & Managing Director of iTips Pvt. Ltd.(India)</span>
        </p>
      </div>
      <div>
        <p className={ProfileStyles.label}>Message</p>
        <p className={ProfileStyles.message}>
          <span>{`Life never be fair, and that's just how it is.`}</span>
          <span>
            {`Nobody gets same opportunities, wealth, human rights, and even love and lifespan.`}
          </span>
          <span>{`This is the reality of our life.`}</span>
          <span>{`However, there is inevitable truth.`}</span>
          <span>
            {`Nothing more powerful than our own Hard Work to get our life better.And I firmly believe this.`}
          </span>
          <span>
            {`We are determined to create and provide various opportunities for all the Hard Workers to achieve their bright future.`}
          </span>
        </p>
      </div>
    </div>
  );
};

export const Profile = () => {
  const [open, setOpen] = useState(false);
  const onClickIcon = () => {};
  return (
    <Collapsible.Root className={ProfileStyles.container} open={open} onOpenChange={setOpen}>
      <div className={ProfileStyles.firstView}>
        <div className={ProfileStyles.left}>
          <MyImage src={'/company/company_ceo.webp'} />
        </div>
        <div className={ProfileStyles.right}>
          <p className={ProfileStyles.name}>Ratnesh Kumar</p>
          <p className={ProfileStyles.jobname}>Founder & Representative Director</p>
          <Collapsible.Trigger asChild>
            <AiOutlineArrowRight onClick={onClickIcon} />
          </Collapsible.Trigger>
        </div>
      </div>
      <Collapsible.Content>
        <ProfileText />
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
