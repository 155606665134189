// extracted by mini-css-extract-plugin
export var container = "Profile-module--container--uFmLW";
export var firstView = "Profile-module--firstView--pNtWg";
export var jobname = "Profile-module--jobname--bgKx1";
export var label = "Profile-module--label--oZaup";
export var left = "Profile-module--left--2L-CB";
export var message = "Profile-module--message--HR5gg";
export var month = "Profile-module--month--Xr6GG";
export var name = "Profile-module--name--X7yKu";
export var right = "Profile-module--right--kQKSg";
export var text = "Profile-module--text--zaSpY";
export var textContainer = "Profile-module--textContainer--0-aXT";
export var year = "Profile-module--year--onb4z";