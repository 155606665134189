import React from 'react';

import { MyImage } from '@/components/common/MyImage';

import * as LocationStyles from './Location.module.scss';

export const LocationComp = ({
  title,
  paragraph,
  imageUrl,
  locationURL,
  id,
}: {
  title: string;
  paragraph: string;
  imageUrl?: string;
  locationURL: string;
  id: string;
}) => {
  return (
    <div className={LocationStyles.container} id={id}>
      <div className={LocationStyles.descriptionWrapper}>
        <div className={LocationStyles.description}>
          <p className={LocationStyles.title}>{title}</p>
          <p className={LocationStyles.text}>{paragraph}</p>
        </div>
        {imageUrl && <MyImage src={imageUrl} />}
      </div>
      <div className={LocationStyles.map}>
        <iframe loading="lazy" src={locationURL} width="100%" height="250px"></iframe>
      </div>
    </div>
  );
};
