import { motion, Variants } from 'framer-motion';
import React from 'react';

import { MyImage } from '../../common/MyImage';
import * as AboutCircleCaptionStyle from './AboutCircleCaption.module.scss';

const circleVariants: Variants = {
  offscreen: {
    y: 100,
    scale: 0.8,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const AboutCircleCaption = ({ caption, imgUrl }: { caption: string; imgUrl: string }) => {
  return (
    <motion.div
      whileTap={{ scale: 1.2 }}
      variants={circleVariants}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0 }}
      className={AboutCircleCaptionStyle.container}
    >
      <MyImage src={imgUrl} />

      <div className={AboutCircleCaptionStyle.caption}>
        <p>{caption}</p>
      </div>
    </motion.div>
  );
};
