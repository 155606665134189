import React from 'react';

import * as PrimaryButtonStyles from './PrimaryButton.module.scss';

export const PrimaryButton = ({ text }: { text: string }) => {
  return (
    <button className={PrimaryButtonStyles.wrapper}>
      <span>{text}</span>
    </button>
  );
};
