import styled from '@emotion/styled';
import { motion, Variants } from 'framer-motion';
import React from 'react';

interface Props {
  children: React.ReactNode;
  delay?: number;
  y?: number;
  className?: string;
}

function setVariants(delay?: number, y?: number) {
  const fadeInBottom: Variants = {
    offscreen: {
      opacity: 0,
      y: y ? y : 50,
    },
    onscreen: {
      opacity: 1,
      y: 0,

      transition: {
        duration: 0.3,
        delay: delay ? delay : 0.0,
        // ease: [0, 0.71, 0.2, 1.01],
      },
    },
  };
  return fadeInBottom;
}

const FadeInBottom = (props: Props) => {
  return (
    <Wrapper
      className={props.className}
      variants={setVariants(props.delay, props.y)}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0 }}
    >
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)``;

export default FadeInBottom;
