import React, { useEffect } from 'react';

import FadeInBottom from '@/components/common/FadeInBottom';
import { ItipsLogo } from '@/components/common/Logo';
import { MyImage } from '@/components/common/MyImage';
import { PrimaryButton } from '@/components/common/PrimaryButton';
import { AboutCircleCaption } from '@/components/pages/home/AboutCircleCaption';
import { LocationComp } from '@/components/pages/home/Location';
import { Locations } from '@/components/pages/home/Locations';
import { Profile } from '@/components/pages/home/Profile';

import Footer from '../components/common/Footer';
import Header from '../components/Header';
import * as TopLocationStyles from '../components/pages/home/TopLocationStyle.module.scss';
import * as AboutStyles from '../components/pages/home/TopPageAbout.module.scss';
import * as CompanyStyles from '../components/pages/home/TopPageCompany.module.scss';
import * as ProfileStyles from '../components/pages/home/TopPageProfile.module.scss';
import * as TopStyles from '../components/pages/home/TopPageTop.module.scss';
import * as VisionStyles from '../components/pages/home/TopPageVision.module.scss';
import Contact from './contact';

const TopPageMission = ({
  imgUrl,
  mainSentence,
  subSentence,
}: {
  imgUrl: string;
  mainSentence: string;
  subSentence: string;
}) => {
  return (
    <div className={VisionStyles.content}>
      <div className={VisionStyles.titleImage}>
        <MyImage src={imgUrl} />
      </div>
      <div className={VisionStyles.sentenceContainer}>
        <p className={VisionStyles.sentenceMain}>{mainSentence}</p>
        <p className={VisionStyles.sentenceSub}>{subSentence}</p>
      </div>
    </div>
  );
};
const AboutLogo = ({ text, title, caption }: { text: string; title: string; caption: string }) => {
  return (
    <tr>
      <td className={CompanyStyles.letter}>
        <p>{text}</p>
      </td>
      <td className={CompanyStyles.letterTitle}>
        <p>{title}</p>
      </td>
      <td className={CompanyStyles.letterCaption}>
        <p>{caption}</p>
      </td>
    </tr>
  );
};

const IndexPage = () => {
  useEffect(() => {
    const gatsbyFocusWrapper = document.getElementById('gatsby-focus-wrapper');
    if (gatsbyFocusWrapper) {
      gatsbyFocusWrapper.removeAttribute('style');
      gatsbyFocusWrapper.removeAttribute('tabIndex');
    }
  }, []);

  return (
    <main>
      <Header />

      <div className={TopStyles.container}>
        <div className={TopStyles.topImage}>
          <MyImage src="/topImage.webp" />
        </div>
        <div className={TopStyles.sentenceWrapper}>
          <h1 className="english">
            Best Wishes <br />
            for all the Hard Worker
            <br />
          </h1>
        </div>
      </div>

      <div className={VisionStyles.container} id="mission">
        <div className={VisionStyles.background}>
          <MyImage src="/grid.webp" />
        </div>

        <div className={VisionStyles.contentWrapper}>
          <TopPageMission
            imgUrl="/vision.webp"
            mainSentence="Japanese technology and craftsmanship to the world."
            subSentence="We have been engaged in various projects, both Japanese and non-Japanese, in the public and private sectors, and have been engaged in the construction business, including design and construction management of building projects, quality surveys, and installation of solar panels, We also manufacture necessary building materials locally at our own factory."
          />
          <TopPageMission
            imgUrl="/mission.webp"
            mainSentence="Japanese technology and craftsmanship to the world."
            subSentence="We are committed to the global business development of Japanese craftsmen through the transmission of skills and technics of the manufacturing and construction industries."
          />

          <FadeInBottom>
            <MyImage src="/mission_main_image.png" />
          </FadeInBottom>

          <MyImage src="/mission_bottom.png" />
        </div>
      </div>

      <div className={AboutStyles.container} id="about">
        <p className={AboutStyles.title}>About “iTips”</p>

        <h1 className={AboutStyles.serviceTitle}>SERVICE</h1>

        <div className={AboutStyles.serviceContent}>
          <AboutCircleCaption imgUrl="/about_service_construction.webp" caption="Manufacturing" />

          <AboutCircleCaption
            imgUrl="/about_service_education.webp"
            caption="Human Resources Development"
          />
        </div>

        <div className={AboutStyles.bottomButton}>
          <PrimaryButton text="More Detail" />
        </div>
      </div>

      <div className={ProfileStyles.container}>
        <div className={ProfileStyles.titleWrapper}>
          <MyImage src="/Company.webp" />
          <p className="title-text">
            We have two offices in Japan and India to expand our business globally.
          </p>
        </div>

        <div className={ProfileStyles.profileWrapper}>
          <h2>About us</h2>
          <div className={ProfileStyles.profile}>
            <Profile />
          </div>
        </div>
      </div>

      <div className={CompanyStyles.container} id="company">
        <div className={CompanyStyles.left}>
          <ItipsLogo />
          <p className={CompanyStyles.heading}>Company Profile</p>
          <table className={CompanyStyles.table}>
            <tr>
              <td>Company Name</td>
              <td>iTips Inc.</td>
            </tr>
            <tr>
              <td>Established</td>
              <td>January 2022</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                Address 4-60-12 11F Global Gate Bldg, Hiraike-Cho, Nakmura-ku, Nagaoya, Aichi, Japan
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>President</td>
              <td>CEO Ratnesh Kumar</td>
            </tr>
            <tr>
              <td></td>
              <td>Executive Masafumi Soga </td>
            </tr>
            <tr>
              <td>Oversea Branch</td>
              <td>iTips Private Limited</td>
            </tr>
          </table>
        </div>
        <div className={CompanyStyles.right}>
          <table>
            <AboutLogo
              title="藍（あい）"
              text="i"
              caption={`AI ( "Indigo" in Japanese) Xunzi(Chinese philosopher)'s saying goes "Although blue
                  dye comes from indigo plant and is bluer than indigo"`}
            />
            <AboutLogo title="Tenacity" text="T" caption={'Take on the challenge with tenacity.'} />
            <AboutLogo
              title="Initiative"
              text="i"
              caption={'Take the initiative in solving problems'}
            />
            <AboutLogo
              title="Passion"
              text="p"
              caption={'Toward problem-solving, To take the initiative in solving problems'}
            />
            <AboutLogo
              title="SHOKUNIN"
              text="s"
              caption={`To pass on "craftsmanship" to the next generationto the next generation.`}
            />
          </table>
        </div>
      </div>

      <div className={TopLocationStyles.container}>
        <div className={TopLocationStyles.title}>
          <p>Office</p>
        </div>
        {Locations.map((e, i) => {
          return (
            <FadeInBottom key={i}>
              <LocationComp
                title={e.title}
                paragraph={e.paragraph}
                imageUrl={e.imageUrl ?? undefined}
                id={e.id}
                locationURL={e.locationURL}
              />
            </FadeInBottom>
          );
        })}
      </div>

      <Contact />

      <Footer />
    </main>
  );
};

export default IndexPage;
